<template>
  <b-container :class="['py-md-4', style['cart-container']]">
    <LoadingSpinner v-if="loading" />
    <h2
      class="
          font-weight-bold
          mb-md-5 mb-4
          mt-5
          display-4
        "
    >
      Shopping Cart
    </h2>
    <shopping-card
      v-if="window.width >= 768"
      :cartList="cartList"
      @remove="handleRemove"
    />
    <shopping-card-sm
      v-if="window.width < 768"
      :cartList="cartList"
    />
    <div
      v-if="cartList.length > 0"
      class="h3 font-weight-bold"
    >
      <p class="border-bottom mt-5">
        Price Details
      </p>
      <div :class="style['total-wrapper']">
        <b-row class="border-bottom">
          <b-col
            md="10"
            class="font-open-sans font-weight-light h6 py-3"
          >
            Sub Total
          </b-col>
          <b-col class="h6 py-3 font-weight-light">
            ${{ parseFloat(total).toFixed(2) }}
          </b-col>
        </b-row>
        <b-row class="border-bottom mt-2">
          <b-col
            md="10"
            class="font-open-sans font-weight-light h6 py-3"
          >
            Free Shipping
          </b-col>
          <b-col class="h6 py-3 font-weight-light">
            $0.00
          </b-col>
        </b-row>
        <b-row :class="[style['total'],'border-bottom']">
          <b-col
            md="10"
            class="h6 pt-3 pb-2"
          >
            Total
          </b-col>
          <b-col class="h6 pt-3 pb-2">
            ${{ parseFloat(total).toFixed(2) }}
          </b-col>
        </b-row>
      </div>
      <div :class="window.width < 768 ? 'text-center' : 'text-right'">
        <b-button
          class="px-4 text-uppercase mt-3"
          pill
          variant="primary"
          @click="handleCheckout"
        >
          Checkout
        </b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import style from '../styles/shopping.module.scss';
import ShoppingCard from '../components/Shopping/ShoppingCartCard.vue';
import ShoppingCardSm from '../components/Shopping/ShoppingCardSm.vue';
import { cartProductList } from '../api/shopping';
import LoadingSpinner from '../components/Common/LoadingSpinner';
export default {
    name:'ShoppingCart',
    components:{ ShoppingCard, ShoppingCardSm, LoadingSpinner },
    data(){
        return {
            style,
            window: {
                width: 0,
                height: 0
            },
            loading: false,
            cartList: [],
            total:0,
            subTotal:0,
        };
    },
    async created(){
        let price = 0;
        this.loading = true;
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        let list = await cartProductList();
        if (list){
            this.loading = false;
            this.cartList = list;
            list.map(value => {
                price = value.product.price + price;
            });
            this.total = price;
        } else {
            this.loading = false;
        }
        if (document.getElementById('newsFeed') !== null){
            document.getElementById('newsFeed').firstChild.classList.remove('router-link-active');
        }
    },
    destroyed(){
        window.addEventListener("resize", this.handleResize);
    },
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        handleRemove(price, productId){
            this.total = this.total-price;
            let that = this;
            //this.cartList = list;
            this.cartList.findIndex(function(current, index) {
                if (current.id === productId) {
                    that.cartList.splice(index, 1);
                }
            });
        },
        async handleCheckout(){
            let ObjToSend = {
                amount: this.total,
                shipping: 0
            };
            this.$router.push({path:'/cart/checkout', query:ObjToSend});
        }
    }
};
</script>
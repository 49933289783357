<template>
  <b-modal
    id="modal-center"
    v-model="show"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    size="md"
    body-class="text-center pb-5"
    header-class="border-bottom-0"
  >
    <template
      v-slot:modal-header=""
    >
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="handleClose"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <p class="display-4 font-weight-bold">
      Are you sure ?
    </p>

    <b-button
      variant="primary"
      class="btn-primary rounded-pill px-5 mb-4 mr-3"
      @click="handleClose"
    >
      No
    </b-button>
    
    <b-button
      class="btn btn-primary rounded-pill px-5 mb-4"
      variant="primary"
      @click="onDelete"
    >
      Yes
    </b-button>
  </b-modal>
</template>

<script>
import IconClose from '../../assets/svg-import/close.svg';
export default {
    name: 'Logout',
    components: {IconClose},
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {};
    },
    methods: {
        handleClose() {
            this.$emit('onClose');
        },
        onDelete(){
            this.$emit('onClose');
            this.$emit('onDelete');
        }
    }
};
</script>

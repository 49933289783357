<template>
  <section>
    <delete-product
      :show="show"
      @onClose="closeDeleteProduct"
      @onDelete="handleRemove"
    />
    <template v-if="productList.length > 0">
      <b-row
        v-for="(data, index) in productList"
        :key="index + '_' + data.id"
        class="border mt-3"
      >
        <b-col
          lg="2"
          md="2"
          class="pr-0"
          style="cursor:pointer;"
          @click="handleRedirection(data)"
        >
          <img
            :id="`product_${data.id}`"
            :src="data.image"
            :class="[style['product-image'], 'my-3 ml-3']"
            @error="handleError(`product_${data.id}`)"
          >
        </b-col>
        <b-col
          lg="6"
          md="6"
          :class="[style['product-details'], 'mt-5']"
          style="cursor:pointer;"
          @click="() => $router.push(`/product/${data.productId}`)"
        >
          <span class="text-uppercase">{{ data.name }}</span>
        </b-col>
        <b-col
          :class="[style['product-details'], 'mt-5']"
          md="2"
        >
          <span>$ {{ parseFloat(data.price).toFixed(2) }} </span>
        </b-col>
        <b-col
          :class="[style['product-details'], 'mt-5']"
          md="0"
        >
          <b-button
            class="px-4 text-uppercase btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3"
            pill
            @click="openDeleteProduct(data)"
          >
            Remove
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="border mt-3 text-center py-5 mb-5">
        <b-col class="h4 font-weight-bold mt-2 py-5 mb-4">
          No items found
        </b-col>
      </b-row>
    </template>
  </section>
</template>

<script>
import style from "../../styles/shopping.module.scss";
import placeholderImage from "../../assets/images/img-prod-placeholder.png";
import { removeFromCart } from "../../api/shopping";
import DeleteProduct from "./DeleteProduct";
export default {
    name: "ShoppingCartCard",
    components: { DeleteProduct },
    props: {
        cartList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            style,
            show: false,
            productList: [],
            productId: "",
        };
    },
    watch: {
        cartList: function(newVal) {
            this.productList = [];
            newVal.map((value) => {
                let objToPush = {
                    id: value.id,
                    image:
            value.product.product_images.length > 0
                ? value.product.product_images[0].p_image
                : placeholderImage,
                    name: value.product.name,
                    price: value.price,
                    productId: value.product.id,
                    fabId: value.product.added_by.id,
                    fabUsername: value.product.added_by.username,
                };
                this.productList.push(objToPush);
            });
            let productsInCart = JSON.stringify(this.productList);
            localStorage.setItem("productsList", productsInCart);
            let productCount = this.productList.length;
            localStorage.setItem("totalProducts", productCount);
            document.getElementById("badge").innerHTML = productCount;
            document.getElementById("badge").style.opacity =
        productCount === 0 ? 0 : 1;
        },
    },
    mounted() {
        this.cartList.map((value) => {
            let objToPush = {
                id: value.id,
                image:
          value.product.product_images.length > 0
              ? value.product.product_images[0].p_image
              : placeholderImage,
                name: value.product.name,
                price: value.price,
                productId: value.product.id,
                fabId: value.product.added_by.id,
                fabUsername: value.product.added_by.username,
            };
            this.productList.push(objToPush);
            let productsInCart = JSON.stringify(this.productList);
            localStorage.setItem("productsList", productsInCart);
            let productCount = this.productList.length;
            localStorage.setItem("totalProducts", productCount);
            document.getElementById("badge").innerHTML = productCount;
            document.getElementById("badge").style.opacity =
        productCount === 0 ? 0 : 1;
        });
        if (this.cartList.length == 0) {
            localStorage.setItem("totalProducts", 0);
            document.getElementById("badge").innerHTML = 0;
            document.getElementById("badge").style.opacity = 0;
        }
    },
    methods: {
        async handleRemove() {
            this.show = false;
            let remove = await removeFromCart(this.productId);
            if (remove.status) {
                let me = this;
                let totalProducts = document.getElementById("badge").innerHTML;
                let productList = JSON.parse(localStorage.getItem("productsList"));
                this.productList.findIndex(function(current, index) {
                    if (current.id === me.productId) {
                        me.productList.splice(index, 1);
                        me.$emit("remove", current.price, me.productId);
                        productList.splice(index, 1);
                        localStorage.setItem("productsList", JSON.stringify(productList));
                        let newCount = parseInt(totalProducts) - 1;
                        localStorage.setItem("totalProducts", newCount);
                        document.getElementById("badge").innerHTML =
              newCount === 0 ? "" : 1;
                        document.getElementById("badge").style.opacity =
              newCount === 0 ? 0 : 1;
                    }
                });
            }
        },
        handleError(data) {
            document.getElementById(data).src = placeholderImage;
        },
        closeDeleteProduct() {
            this.show = false;
        },
        openDeleteProduct(data) {
            this.productId = data.id;
            this.show = true;
        },
        handleRedirection(data) {
            localStorage.setItem("fabId", data.fabId);
            localStorage.setItem("fabUsername", data.fabUsername);
            this.$router.push(`/product/${data.productId}`);
        },
    },
};
</script>

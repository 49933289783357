<template>
  <section>
    <template v-if="productList.length">
      <b-card 
        v-for="(data, index) in productList"
        :key="index+'_'+data.id"
        :class="[style['shop-card'], 'mb-4']"
      >
        <b-img
          :id="`product_${data.id}`"
          :src="data.image"
          :alt="data.name"
          fluid
          @error="handleError(`product_${data.id}`)"
        />
        <p class="text-uppercase text-left mt-1 text-truncate mb-0">
          {{ data.name }}
        </p>
        <p class="text-left mb-0">
          ${{ parseFloat(data.price).toFixed(2) }}
        </p>
        <div class="text-right">
          <b-button
            pill
            :class="['text-uppercase px-4 text-uppercase btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-md-3 px-3']"
            @click="handleRemove(data)"
          >
            Remove
          </b-button>
        </div>
      </b-card>
    </template>
    <template v-else>
      <b-card 
        :class="[style['shop-card'], 'mb-4']"
      >
        No items found
      </b-card>
    </template>
  </section>
</template>

<script>
import style from "../../styles/feed.module.scss";
import placeholderImage from '../../assets/images/img-prod-placeholder.png';
import { removeFromCart } from '../../api/shopping';
export default {
    props:{
        cartList:{
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            style,
            productList:[],
        };
    },
    watch:{
        cartList: function(newVal){
            newVal.map(value => {
                let objToPush = {
                    id: value.id,
                    image: value.product.product_images.length > 0 ? value.product.product_images[0].p_image : placeholderImage,
                    name: value.product.name,
                    price: value.price,
                    fabId: value.product.fab_id
                };
                this.productList.push(objToPush);
            });
            let productsInCart = JSON.stringify(this.productList);
            localStorage.setItem('productsList', productsInCart);
            let productCount = this.productList.length;
            localStorage.setItem('totalProducts', productCount);
            document.getElementById('badge').innerHTML = productCount;
            document.getElementById('badge').style.opacity = 1;
        }
    },
    created(){
        this.cartList.map(value => {
            let objToPush = {
                id: value.id,
                image: value.product.product_images.length > 0 ? value.product.product_images[0].p_image : placeholderImage,
                name: value.product.name,
                price: value.price,
                fabId: value.product.fab_id
            };
            this.productList.push(objToPush);
            let productsInCart = JSON.stringify(this.productList);
            localStorage.setItem('productsList', productsInCart);
            let productCount = this.productList.length;
            localStorage.setItem('totalProducts', productCount);
            document.getElementById('badge').innerHTML = productCount;
        });
    },
    methods:{
        async handleRemove(data){
            let remove = removeFromCart(data.id);
            if (remove){
                let me = this;
                let totalProducts = document.getElementById('badge').innerHTML;
                let productList = JSON.parse(localStorage.getItem('productsList'));
                this.productList.findIndex(function(current, index){
                    if (current.id === data.id){
                        me.productList.splice(index, 1);
                        me.productList.splice(index, 1);
                        productList.splice(index,1);
                        localStorage.setItem('productsList',JSON.stringify(productList));
                        let newCount = parseInt(totalProducts)-1;
                        localStorage.setItem('totalProducts', newCount);
                        document.getElementById('badge').innerHTML = newCount;
                        document.getElementById('badge').style.opacity = newCount === 0 ? 0 : 1 ;
                    }
                });
            }
        },
        handleRedirection(data){
            localStorage.setItem('fabId', data.fabId);
            this.$router.push(`/product/${data.productId}`);
        },
        handleError(data){
            document.getElementById(data).src = placeholderImage;
        }
    }
};
</script>